import { forwardRef, useState } from 'react';
import {
  Button, Dialog, DialogActions, Slide
} from '@material-ui/core'

import './modal.css'
import api from '../../utils/services';
import axios from 'axios'
import Swal from 'sweetalert2';

// import ImagemModal from '../../assets/important.png'

const initalValuesAddress = {
  state: "",
  city: "",
  street: "",
  zipCode: "",
  
  default: true,
  complement: "",
  neighborhood: "",
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewAddress = ({ onSubmit = () => { }, onClose = () => { }, allAddress, setAllAddress}) => {

  const [open, setOpen] = useState(true);
  const [address, setAddress] = useState(initalValuesAddress)
  const [number, setNumber] = useState(0)


  const handleChangeAddress = (event) => {
    const { name, value } = event.target
    // console.log(name, value)
    setAddress({ ...address, [name]: value })
  }

  const handleSubmit = () =>{

    let data = {
      state: address.state,
      city: address.city,
      street: address.street,
      zipCode: address.zipCode,
      number: parseInt(number),
      default: false,
      complement: address.complement,
      neighborhood: address.neighborhood,
    }

    api.post('/addresses', data).then( res => {
      Swal.fire({
        title: 'Sucesso',
        text: 'Endereço cadastrado com sucesso!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then( _ => setAllAddress([...allAddress, res.data]))
    }).catch((err) => {
      console.log(err)
      Swal.fire({
        title: 'Erro',
        text: 'Algo deu errado, tente novamente!',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    })
  }

  const handleAlert = () =>{
    Swal.fire({
      title: 'Certeza que deseja cadastrar esse endereço?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: "#3085d6",
      denyButtonColor: "#d33",
      denyButtonText: `Não`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleSubmit()
      } else if (result.isDenied) {
        Swal.fire('Ok! informações não cadastrada', '', 'warning')
        handleClose();
      }
    })
  }

 /*
  const handleChangeAddress = (event) => {
    const { name, value } = event.target
    // console.log(name, value)
    setNewAddress({ ...newAddress, [name]: value })
  } */

  const onBLurCep = async (cep) =>{
    await axios.get(`http://viacep.com.br/ws/${cep}/json`).then( res => {
      setAddress(prevState => {
        return { ...prevState, city: res.data.localidade }
    });
      setAddress(prevState => {
        return { ...prevState, state: res.data.uf }
    });
      console.log(address)
    }).catch( err => console.log('Erro cep', err))
  }

  const handleClose = () => {
    onClose()
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='container-modal'>
          {/* <img src={ImagemModal} alt="" /> */}
          <div className='content-modal'>
            
          <div className="form-edit-row">
                <h3>Cep: </h3>
                <input
                  id="input-focus-width"
                  type="text"
                  name="zipCode"
                  onChange={handleChangeAddress}
                  onBlur={ (e) => onBLurCep(e.target.value)}
                />
              </div>
    
              <div className="form-edit-row">
                <h3>Estado: </h3>
                <input
                  defaultValue={address.state}
                  id="input-focus-width"
                  type="text"
                  name="state"
                  onChange={handleChangeAddress}
                   />
              </div>
              
              <div className="form-edit-row">
                <h3>Cidade: </h3>
                <input
                  defaultValue={address.city}
                  id="input-focus-width"
                  type="text"
                  name="city"
                  onChange={handleChangeAddress}
                   />
              </div>
    
              <div className="form-edit-row">
                <h3>Rua: </h3>
                <input
                  id="input-focus-width"
                  type="text"
                  name="street"
                  onChange={handleChangeAddress}
                   />
              </div>
    
              <div className="form-edit-row">
                <h3>Número: </h3>
                <input
                  id="input-focus-width"
                  type="number"
                  name="number"
                  onChange={(ev) => setNumber(ev.target.value)}
                   />
              </div>
    
              <div className="form-edit-row">
                <h3>Complemento: </h3>
                <input
                  id="input-focus-width"
                  
                  type="text"
                  name="complement"
                  onChange={handleChangeAddress}
                   />
              </div>
    
              <div className="form-edit-row">
                <h3>Bairro: </h3>
                <input
                  id="input-focus-width"
                  type="text"
                  name="neighborhood"
                  onChange={handleChangeAddress}
                  />
              </div>
           

          </div >
          <DialogActions>
            <Button id="btn-secundary-modal" onClick={onClose}>Não</Button>
            <Button id="btn-primary-modal"  onClick={() => { handleAlert() }}>Cadastrar</Button>
            {/* <Button onClick={onSubmit}>Agree</Button> */}
          </DialogActions>
        </div>
      </Dialog>
    </div>
  )
}

export default NewAddress
