import { React, useState, useMemo, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import LogoBranca from '../../assets/logo-branca.png'
import DefaultProfile from '../../assets/default-profile.png'
import { history } from '../../history'
import { login } from '../../utils/auth'

import { Formik, Field, } from 'formik'
import Swal from 'sweetalert2'

import './styles.css'

// import CategoriasProdutosJSON from './product.json';
// import CategoriasServicosJSON from './services.json';

import CpfInputMask from '../../components/MaskedInput'
import { Box, Modal, Typography } from '@material-ui/core';
import api from '../../utils/services';


const styleModal = {
  display: 'flex',
  alignItems: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Cadastro = () => {
  const [file, setFile] = useState('');

  const preview = useMemo(() => {
    return file ? URL.createObjectURL(file) : null;
  }, [file]);

  const [cpfOrCnpj, setCpfOrCnpj] = useState(false)
  const [isCpf, setIsCpf] = useState(false)

  const [readTerm, setReadTerm] = useState(false)

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onBlurPassword = (event, values) => {
    const password = event.target.value;

    if (password !== values.password) {
      Swal.fire({
        title: 'Informação!',
        text: 'Senhas diferentes. Informe senhas iguais!',
        icon: 'warning',
        confirmButtonText: 'OK'
      })
    }
  }

  async function onSubmit(values, actions) {

    if (!readTerm) return alert('Aceite os termos')

    // console.log(values)
    let formData = new FormData();

    Object.entries(values).map(([key, value]) => {
      if (key === 'confirmationPassword' || key === 'type') {
        return null;
      } else if (value !== undefined && value !== '' && value !== null) {
        return formData.append(key, value);
      }
    });

    // Object.entries(values).map(([key, value]) => {
    //   if (key === "confirmationPassword" || key === "type") {
    //     return null;
    //   } 
    //   if (values.hasOwnProperty(key) && values[key] !== '') {
    //     formData.append(key, values[key]);
    //   }
    // })


    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }


    api.post("/establishments", formData,)
      .then(response => {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Loja cadastrada, bora para próxima etapa!',
          icon: 'success',
          confirmButtonText: 'Prosseguir'
        })
        login(response.data.user.accessToken)
        history.push('/cadastro-endereco')
      }).catch(response => {
        Swal.fire({
          title: 'Informação!',
          text: 'Algo deu errado, tente novamente! Talvez algum dado pode estar repetido',
          icon: 'warning',
          confirmButtonText: 'OK'
        })

        console.log(response)
      });


  }


  function onChangeImg(ev, setFieldValue) {
    setFieldValue('profileImage', ev.target.files[0])
    setFile(ev.target.files[0])
  }

  return (
    <>
      <div className="container">
        <div className="content">
          <img id="logo" onClick={() => history.push('login')} src={LogoBranca} alt="" />

          <Formik
            onSubmit={onSubmit}
            initialValues={{
              establishmentName: "",
              description: '',
              companyType: '',
              openingDate: '',
              delivery: null,
              name: '',
              lastName: '',
              email: '',
              password: "",
              birthDate: "",
              cpfCnpj: "",
              news: "true",
              phone: "",
              whatsAppNumber: "",
              profileImage: "",
              roles: 'establishment',
              type: '',
              closed: 'false',
              serviceRadius: '0',
              deliveryFee: '0',
            }
            }
            render={({ values, handleSubmit, handleChange, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="box-form">

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="modalConfimation" >
                    <div className='modalConfimationContainer' >
                      <h2>Confirme seus dados</h2>

                      <div className="modalConfirmationContent" >
                        <h3>Dados pessoais</h3>
                        <ul>
                          <li>Nome: <span>{values.name}</span></li>
                          <li>Sobre Nome: <span>{values.lastName}</span></li>
                          <li>Data de Nascimento: <span>{values.birthDate.split('-').reverse().join('/')}</span></li>
                          <li>CPF/CNPJ: <span>{values.cpfCnpj}</span></li>
                          <li>Telefone: <span>{values.phone}</span></li>
                          <li>email: <span>{values.email}</span></li>
                          {/* <li>Senha: <span>{values.name}</span></li> */}
                        </ul>
                      </div>

                      <div className="modalConfirmationContent" >
                        <h3>Dados do empreendimento</h3>
                        <ul>
                          <li>Nome: <span>{values.establishmentName}</span></li>
                          <li>Descrição: <span>{values.description}</span></li>
                          <li>Data de Abertura: <span>{values.openingDate.split('-').reverse().join('/')}</span></li>
                          <li>Tipo do Empreendimento: <span>{values.companyType}</span></li>
                          <li>Telefone: <span>{values.whatsAppNumber}</span></li>
                          <li>Possui delivery?*: <span>{values.delivery === true ? 'Sim' : 'Não'}</span></li>
                          {
                            values.serviceRadius !== "" &&
                            <li>Raio de entrega por km: <span>{values.serviceRadius}km</span></li>
                          }
                          {
                            values.deliveryFee !== "" &&
                            <li>Taxa de entrega: <span>R$ {parseFloat(values.deliveryFee).toFixed(2)}</span></li>

                          }

                          <li>Loja aberta?*: <span>{values.closed === true ? 'Não' : 'Sim'}</span></li>
                        </ul>
                      </div>

                      <div id="btn-modal-confirmation"  >
                        <button onClick={handleClose} style={{ backgroundColor: 'var(--red)' }} >Cancelar</button>
                        <button onClick={() => {
                          handleClose();
                          onSubmit(values);
                        }} style={{ backgroundColor: 'var(--primary-cor)' }} >Confirmo</button>
                      </div>

                    </div>
                  </Box>
                </Modal>

                <div className="box">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                    <h1>Informe seus dados pessoais</h1>
                    <h4 style={{ color: '#38a2e9' }} >Etapa 1 de 4</h4>
                  </div>
                  <div className="input-column" >

                    <div className="input-row">
                      <div className="input-container">
                        <label>Nome*</label>
                        <Field placeholder="Insira seu nome" required type="text" className="c2" name="name" />
                      </div>

                      <div className="input-container">
                        <label>Sobrenome*</label>
                        <Field placeholder="Sobre nome completo" required className="c2" type="text" name="lastName" />
                      </div>
                    </div>

                    <div className="input-row">
                      <div className="input-container">
                        <label>Data de Nascimento*</label>
                        <Field required className="c2" type="date" name="birthDate" />
                      </div>

                      <div className="input-container">
                        <label className="justify-label" >CPF/CNPJ*
                          {cpfOrCnpj === false
                            ? <span></span>
                            : <span style={{ cursor: 'pointer' }} onClick={() => setCpfOrCnpj(false)}>
                              <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="var(primary-cor)" className="bi bi-arrow-return-left">
                                <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                              </svg>
                            </span>}
                        </label>
                        {
                          cpfOrCnpj === false
                            ?
                            <div style={{ margin: 0 }} >
                              <button className="btn-cpfCnpj" onClick={() => { setCpfOrCnpj(true); setIsCpf(true) }} type="button">CPF</button>
                              <button style={{ marginLeft: 10 }} className="btn-cpfCnpj" onClick={() => { setCpfOrCnpj(true); setIsCpf(false) }} type="button">CNPJ</button>
                            </div>
                            :
                            <div style={{ margin: 0 }} >
                              {
                                isCpf === true
                                  ? <CpfInputMask
                                    name="cpfCnpj"
                                    mask="999.999.999-99"
                                    value={values.cpfCnpj}
                                    placeholder="Digite seu CPF"

                                    onChange={(event) => setFieldValue('cpfCnpj', event.target.value)} />
                                  : <CpfInputMask
                                    name="cpfCnpj"
                                    mask="99.999.999/9999-99"
                                    value={values.cpfCnpj}
                                    onChange={(event) => setFieldValue('cpfCnpj', event.target.value)}
                                    placeholder="Digite seu CNPJ"
                                  />

                              }
                            </div>
                        }
                      </div>

                    </div>

                  </div>

                  <div className="input-column" >
                    <div className="input-container">
                      <label>E-mail*</label>
                      <Field required placeholder="Insira seu e-mail" type="email" className="c1" name="email" />
                    </div>

                    <div className="input-row">
                      <div className="input-container">
                        <label>Senha*</label>
                        <Field placeholder="Informe sua senha" required type="password" className="c2" name="password" />
                      </div>
                      <div className="input-container">
                        <label>Confirme a senha*</label>
                        <Field required placeholder="Informe a mesma senha" onBlur={(ev) => onBlurPassword(ev, values, setFieldValue)} type="password" className="c2" name="confirmationPassword" />
                        <ToastContainer />
                      </div>
                    </div>
                  </div>

                  <div className="input-row">
                    <div className="input-container c2">
                      <label>Telefone*</label>
                      <CpfInputMask
                        name="phone"
                        mask="(99) 9 9999-9999"
                        value={values.phone}
                        onChange={(event) => setFieldValue('phone', event.target.value)}
                      />

                    </div>

                  </div>

                  <h1 style={{ marginTop: 40 }}>Informe os dados do empreendimento</h1>
                  <div className="input-column" >

                    <div className="input-container">
                      <label>Nome do Empreendimento*</label>
                      <Field placeholder="Informe o nome do seu empreendimento" required className="c1" type="text" name="establishmentName" />
                    </div>

                    <div className="input-row">

                      <div className="input-container">
                        <label>Data de Abertura*</label>
                        <Field required className="c2" type="date" name="openingDate" />
                      </div>

                      <div className="input-container">
                        <label>Tipo de Empreendimento</label>
                        <select onChange={handleChange} required className="c2" name="companyType" >
                          <option value="" placeholder="Escolha uma opção" >Escolha uma tipo</option>
                          <option value="PF" name="companyType">PF</option>
                          <option value="MEI" name="companyType">MEI</option>
                          <option value="EPP" name="companyType">EPP</option>
                          <option value="LTDA" name="companyType">LTDA</option>
                          <option value="INSTITUITION_NGO_ASSOCIATION" name="companyType">Não Governamental</option>
                        </select>
                      </div>

                    </div>

                  </div>

                  <div className="input-column" >

                    <div className="input-container">
                      <label>Descrição do Empreendimento*</label>
                      <Field required className="c1" type="text" name="description" style={{ resize: 'vertical' }} />
                    </div>

                    {/* <div className="input-row">
                      <div className="input-container">
                        <label>Área abraangente</label>
                        <select required className="c2" name="type" onChange={handleChange} onBlur={() => setCategoryDisabled(false)}>
                          <option value="" placeholder="Escolha uma opção" >Escolha uma tipo</option>
                          <option value="product" name="type">Produto</option>
                          <option value="services" name="type">Serviço</option>
                          <option value="ongs" name="type">ONGs, Associações e Afins</option>
                        </select>
                      </div>

                      <div className="input-container">
                        <label>Categoria </label>
                        <select disabled={categoryDisabled} required className="c2" name="businessAreaCode" onChange={handleChange}  >
                          {
                            categoryDisabled === true
                              ? <option > Escolha um tipo e clique aqui </option>

                              : categoryList(values.type).map((item) => <option key={item.code} value={item.code} name="businessAreaCode" placeholder="Escolha uma opção" >{item.category}</option>)
                          }
                        </select>
                      </div>
                    </div> */}

                  </div>

                  <div className="input-column" >

                    <div className="input-row">
                      <div className="input-container c2">
                        <label>Telefone*</label>
                        <CpfInputMask
                          name="whatsAppNumber"
                          mask="+55 (99) 9 9999-9999"
                          value={values.whatsAppNumber}
                          onChange={(event) => setFieldValue('whatsAppNumber', `+${event.target.value}`)}
                        />
                      </div>

                      {/* <div className="input-container">
                        <label>Renda Mensal*<span style={{ fontSize: 10 }} >Aproximadamente</span></label>
                        <Field required type="number" className="c2" name="monthlyIncome" />
                      </div> */}
                    </div>

                    {/* <div className="input-row">
                      <div  className="input-container">
                        <label>Informe o seu CNAE*</label>
                        <Field maxlength="7" required className="c2" type="text" name="cnae" />
                      </div>
                    </div> */}

                  </div>


                  <div className="input-column-justify" style={{ justifyContent: "space-between !important" }} >

                    <div style={{ marginTop: 30 }} className="input-container">
                      <h1>Adicione uma imagem de perfil</h1>
                      {!preview ? (
                        <>
                          <label htmlFor="profileImage">
                            <img
                              style={{
                                height: 180,
                                width: 180,
                                cursor: 'pointer',
                              }}
                              src={DefaultProfile}
                              alt="Default imagem Profile"
                            />
                          </label>
                        </>
                      ) : (
                        <img
                          src={preview}
                          alt="Foto do produto"
                          style={{ cursor: 'pointer' }}
                          width="250px"
                          height="250px"
                          samesite="none"
                        />
                      )}
                      <input type="file"

                        style={{ width: '100%', opacity: 0, fontSize: 1 }}
                        id="profileImage"
                        name="profileImage"
                        onChange={(ev) => onChangeImg(ev, setFieldValue)}
                      />
                    </div>

                    <div style={{ marginTop: 30 }} className="input-container">
                      <>
                        <h1>Seu empreendimento possui <br /> serviço de entrega?</h1>
                        <div className="btn-delivery">
                          <button style={{ backgroundColor: values.delivery === true ? "var(--primary-cor)" : "#333" }} type="button" onClick={() => setFieldValue('delivery', true)} >Sim</button>
                          <button style={{ backgroundColor: values.delivery === false ? "var(--red)" : "#333" }} type="button" onClick={() => setFieldValue('delivery', false)} >Não</button>
                        </div>

                        {
                          values.delivery &&
                          <>
                            <div className="input-row">
                              <div className="input-container">
                                <label>Qual o valor da taxa de entrega?</label>
                                <Field className="c2" type="number" name="deliveryFee" onWheel={(e) => e.target.blur()} />
                              </div>
                            </div>
                          </>

                        }
                      </>
                      <div className="input-row">
                        <div className="input-container">
                          <label>Raio de atendimento por km</label>
                          <Field className="c2" type="number" name="serviceRadius" onWheel={(e) => e.target.blur()} />
                        </div>
                      </div>
                      <>
                        <h1 style={{ marginTop: '30px' }} >Definir estabelecimento como:</h1>
                        <div className="btn-delivery">
                          <button style={{ backgroundColor: values.closed === false ? "var(--primary-cor)" : "#333" }} type="button" onClick={() => setFieldValue('closed', false)} >Aberto</button>
                          <button style={{ backgroundColor: values.closed === true ? "var(--red)" : "#333" }} type="button" onClick={() => setFieldValue('closed', true)} >Fechado</button>
                        </div>
                      </>
                      <h1 style={{ marginTop: '30px' }} >Você está de acordo <br></br> com os termos e condições <br></br> da plataforma:</h1>
                      <a className='link-term' target='_blank' href="/termo" >Clique e leia</a>
                      <div className="btn-delivery">
                        <button style={{ backgroundColor: readTerm === true ? "var(--primary-cor)" : "#333" }} type="button" onClick={() => setReadTerm(true)} >Sim</button>
                        <button style={{ backgroundColor: readTerm === false ? "var(--red)" : "#333" }} type="button" onClick={() => setReadTerm(false)} >Não</button>
                      </div>
                    </div>

                  </div>
                  <button type="button" onClick={() => {
                    if (readTerm) {
                      handleOpen()
                    } else {
                      Swal.fire({
                        title: 'Atenção!',
                        text: 'Você precisa aceitar o termo de uso para continuar',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                      })
                    }
                  }} className="btn-submit">Criar Conta</button>
                  {/* <button onClick={() => console.log(values.profileImage)}>Próximo</button> */}
                </div>
              </form>
            )}
          />

        </div>
      </div>
    </>
  )
}

export default Cadastro
