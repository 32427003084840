import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import { useEffect } from 'react';

import canceledIMG from '../../../../assets/cancelado.svg';
import formatedCurrencyBr from '../../../../common/formatCurrency';

function getModalStyle() {
  const top = 50;
  const left = 49;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paperModal: {
    position: 'absolute',
    width: '90%',
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    overflow: 'auto',
  },
  paddingModal: {
    padding: theme.spacing(2, 4, 3),
  },
  headerModal: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4, 3),
    backgroundColor: 'var(--primary-cor)',
  },
}));

const ContentModal = ({ open, handleClose, data, handleActionSale }) => {
  console.log(data, 'data');
  const [modalStyle] = useState(getModalStyle);

  const [totalPrince, setTotalPrince] = useState(0);
  const [princes, setPrinces] = useState([]);
  //const [dataTemp, setDataTempo] = useState({item: "", prince: 0.0})

  const classes = useStyles();

  useEffect(() => {
    let value = 0.0;
    let dataTemp = [];
    data.items.map((item) => {
      value = value + parseFloat(item.value * item.quantity);
      dataTemp.push({
        name: item.nome,
        amount: item.quantity,
        prince: item.value,
        total: parseFloat(item.value * item.quantity),
      });
    });
    setTotalPrince(value.toFixed(2));
    setPrinces(dataTemp);
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paperModal}>
        <div className={classes.headerModal}>
          <h2 style={{ color: 'var(--white)' }}>PEDIDO: {data.orderNumber}</h2>
          <button onClick={handleClose} className="btn-closed-modal">
            X
          </button>
        </div>

        <div className={classes.paddingModal}>
          <div className="first-details-pedido-container">
            <div className="details-pedido-content">
              <h3>Detalhes do cliente</h3>
              <h4>Nome: {data.clientName} </h4>
              {/* <h4>Data: {data.hours}</h4> */}
            </div>

            <div className="status-pedido">
              {data.status === 'canceled' && (
                <>
                  <img
                    style={{ width: '50px' }}
                    src={canceledIMG}
                    alt="Icone do status do pedido"
                  />
                  <span>Cancelado</span>
                </>
              )}
            </div>
          </div>

          <div className="details-pedido-container">
            {data.address === null ? null : (
              <div className="details-pedido-content">
                <h3>Detalhes do endereço</h3>
                <h4>CEP: {data.address.zipCode}</h4>
                <h4>Cidade: {data.address.city}</h4>
                <h4>Bairro: {data.address.neighborhood}</h4>
                <h4>
                  Rua: {data.address.street} - Nº {data.address.number}
                </h4>
                <h4>Complemento: {data.address.complement}</h4>
              </div>
            )}
          </div>

          <div className="details-pedido-container">
            <div className="details-pedido-content">
              <h3>Detalhes do pedido</h3>

              <div className="table-pedidos" width="100%">
                {data.items.map((item) => {
                  return (
                    <>
                      <div className="coluna-pedidos">
                        <div>
                          <h3>Item</h3>
                          <h4>{item.name}</h4>
                        </div>

                        <div>
                          <h3>Qtde</h3>
                          <h4>{item.quantity}</h4>
                        </div>

                        <div>
                          <h3>Valor unitário</h3>
                          <h4>R$ {item.value.toFixed(2)}</h4>
                        </div>

                        {/* <div>
                            <h3>Subtotal</h3>
                            <h4>R$ {parseFloat(item.value * item.quantity).toFixed(2) }</h4>
                          </div> */}
                      </div>
                    </>
                  );
                })}
              </div>
              {data.reasonToCanceled && (
                <div>
                  <h3 style={{ marginTop: '16px' }}>
                    MOTIVO DE CANCELAMENTO:{' '}
                  </h3>
                  <p>
                    {data.reasonToCanceled === null
                      ? 'Sem motivo'
                      : data.reasonToCanceled}
                  </p>
                  <span className="obs-pedido"></span>
                </div>
              )}
            </div>
          </div>

          <div className="footer-modal">
            <div className="details-pedido-container">
              <div className="details-pedido-content">
                <h3>Conclusão do pedido</h3>

                <div style={{ display: 'flex', gap: '6px' }}>
                  <h4>Tipo de pagamento: </h4>
                  <h4>
                    {data.paymentType === 'creditCard'
                      ? 'Cartão de Credito'
                      : 'PIX'}
                  </h4>
                </div>

                {/* <h4>Quantidade: {data.items.length}</h4> */}

                <h4>Valor do frete: {formatedCurrencyBr(data.deliveryFee)}</h4>

                {data.discount > 0 && (
                  <div style={{ display: 'flex', gap: '6px' }}>
                    <h4>Desconto: </h4>
                    <h4>{formatedCurrencyBr(data.discount)}</h4>
                  </div>
                )}

                <div style={{ display: 'flex', gap: '6px' }}>
                  <h4>Valor total do pedido: </h4>
                  <h4>{formatedCurrencyBr(data.totalValue)}</h4>
                </div>

                <h1>
                  Valor a receber: {formatedCurrencyBr(data.amountToReceive)}
                </h1>
                {/* <h1>Quantidade a receber: {data.totalValue}</h1> */}
                {/* <h1>Preço: {totalPrince}</h1>    */}
              </div>
              {/* <div className="details-pedido-content">
                <h3>Conclusão do pedido</h3>
                <h4>Quantidade: {data.items.length}</h4>

                <div>
                  <h3>Desconto: </h3>
                  <h4>R$ {data.discount}</h4>
                </div>

                <h1>Preço: {totalPrince}</h1>
              </div> */}
            </div>

            <div className="btn-pedidos">
              <button
                onClick={() => {
                  handleClose();
                }}
                style={{ backgroundColor: 'var(--primary-cor)' }}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContentModal;
