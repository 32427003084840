import { React, useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';

import LogoBranca from '../../../assets/logo-branca.png'
import { history } from '../../../history'


import { useLocation } from 'react-router-dom';
import { Formik, Field } from 'formik'

// import '../../pages/Cadastro/styles.css'

import CpfInputMask from '../../../components/MaskedInput'
import api from '../../../utils/services'
import Swal from 'sweetalert2';

const BankAccountEdit = () => {
  const location = useLocation();
  

  const [accountType, setAccountType] = useState([])
  const [selectedBank, setSelectedBank] = useState('')
  const [cpfOrCnpj, setCpfOrCnpj] = useState(true)
  const [isCpf, setIsCpf] = useState(false)
  const [bankAccout, setBankAccout] = useState({});

  function isEmptyObject(obj) {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  }

  function bankCodeToName(){
    const bankInfo = accountType.find(item => item.number === bankAccout.bankNumber)
    return bankInfo?.name ?? ''
  }

  const onChangeSelectedBankCode = (name) =>{
    const code = accountType.find(item => item.name === name)
    if(code !== undefined){
      setSelectedBank(code.number);
    }
  }

  const handleDataUser = () =>{
     api.get('/establishments/self').then( res =>{
       localStorage.setItem('id', res.data.id)
       Swal.fire({
        title: 'Tudo certo',
        text: 'Suas vendas começam logo ali!!',
        icon: 'success',
        confirmButtonText: 'Acessar o painel!'
      }).then((result) => {
        if (result.isConfirmed) {
          history.push('/dashboard')
        }
      })
       
     })
     .catch( error =>{
      Swal.fire('Algo deu errado, tente novamente', '', 'warning')
       window.location.reload()
     })
 }

  const handleAccountType = () =>{
    api.get('/bank-accounts/available-banks').then( res => {
      setAccountType(res.data)
  
    }).catch(_ => {
      Swal.fire('Algo deu errado, tente novamente', '', 'warning')
    })
  }
  async function onSubmit(values, actions) {

    let data = {...values}

    for (let chave in data) {
      if (data[chave] === "" ) {
          data[chave] = bankAccout[chave]
      }
    }
    
    for (let prop in data) {
      if (data[prop] === undefined) {
        delete data[prop];
      }
    }
    console.log('a', data)

     api.put('bank-accounts', data).then( _ =>{
       handleDataUser()
       history.push('/dashboard')
     }).catch(error =>{
        alert('Algo deu errado, tente novamente')
        console.log('Erro', error)
     } )

  }
  
  useEffect(() =>{
    if(location.state && accountType.length > 0){
      setBankAccout(location.state.bankAccout)
    }
  },[location.state, accountType])

  useEffect(()=>{
    handleAccountType()  
  },[])

  return (
    <div className="container">

      {
        !isEmptyObject(bankAccout) 
        
        ?

        <div className="content">
        <img style={{ marginTop: 30 }} id="logo" onClick={() => history.goBack()} src={LogoBranca} alt="" />

        <Formik
          onSubmit={onSubmit}
          initialValues={{
            holderName: "",
            holderType: "individual",
            holderDocument: "",
            agencyNumber: "",
            agencyDigitNumber: "",
            bankNumber: '',
            accountNumber: '',
            accountDigitNumber: '',
            type: '',
          }
          }
          render={({ values, handleSubmit, handleChange, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="box-form">

              <div className="box">
                 <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} >
                    <h1>Informe dados sobre sua conta bancaria</h1>
                  </div>
                <div className="input-column" >
                  <div className="input-container">
                    <label>Nome do proprietário da conta*</label>
                    <input required className="c1" type="text" name="holderName" onChange={handleChange} defaultValue={bankAccout.holderName} />

                  </div>

                  <div className="input-container">
                      <label className="justify-label" >CPF/CNPJ*</label>
                      <input required className="c1" type="text" name="holderDocument" disabled defaultValue={bankAccout.holderDocument} />
                  </div>

                </div>

                {/* 1 Linha  */}

                <div className="input-column" >
                  <div className="input-row">
                    <div className="input-container">
                      <label>Nome do banco*</label>

                      <input 
                        autoComplete="off" 
                        type="search" 
                        id="search" 
                        onChange={handleChange} 
                        onBlur={() => onChangeSelectedBankCode(values.bankNumber)}
                        name="bankNumber" 
                        placeholder="Pesquisar..." 
                        list="options" 
                        defaultValue={bankCodeToName()} 
                      />

                      {/* <Field required maxlength="6"  type="text" className="c2" name="bankNumber" /> */}
                      <datalist id="options" required className="c2"  >
                        <option value="" placeholder="Escolha uma opção" >Escolha uma tipo</option>
                        {
                          accountType.map((item, index) => {
                            return (
                              <option key={index} value={item.name} name="bankNumber">{item.name}</option>
                            )
                          })
                        }
                                    
                       </datalist>
                    </div>  
                  </div>
                </div>

                <div className="input-column" >
                  <div className="input-row">
                    <div className="input-container">
                      <label>Tipo de conta*</label>
                      <select onChange={handleChange} className="c2" name="type" defaultValue={bankAccout.type} >
                        <option value="" placeholder="Escolha uma opção">Escolha uma tipo*</option>
                        <option value="checking" name="type" >Corrente</option>
                        <option value="savings" name="type" >Poupança</option>    
                       </select>
                    </div>  
                  </div>
                </div>
                

                <div className="input-column" >

                  <div className="input-row">
                    <div className="input-container">
                      <label>Número da agência*</label>
                      <input required type="text" onChange={handleChange} className="c2" name="agencyNumber" defaultValue={bankAccout.agencyNumber} />
                    </div>

                    <div style={{display: values.bankNumber === "104" ? "none" : "flex"}} className="input-container">
                      <label>Dígito</label>
                      <input  type="text" className="c4" onChange={handleChange} name="agencyDigitNumber" defaultValue={bankAccout.agencyDigitNumber}  />
                    </div>

                  </div>

                </div>


                {/* 3 Linha  */}

                <div className="input-column" >
                  <div className="input-row">
                    <div className="input-container">
                      <label>Número da conta*</label>
                      <input required type="text" onChange={handleChange} className="c2" name="accountNumber" defaultValue={bankAccout.accountNumber}  />
                    </div>
                    <div className="input-container">
                      <label>Dígito*</label>
                      <input required type="text" onChange={handleChange} className="c4" name="accountDigitNumber" defaultValue={bankAccout.accountDigitNumber}  />
                    </div>
                  </div>
                </div>
                <button type="button" onClick={() => onSubmit(values)} className="btn-submit">Cadastrar</button>
              </div>
            </form>
          )}
        />
        </div>

        :

        <div className="content">
          <h1>Aconteceu um erro, tente novamente</h1>
        </div>
      }


    </div>
  )
}

export default BankAccountEdit
