import React, { useState, useEffect } from 'react'
// import Card from "react-credit-cards";

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';


import api from '../../utils/services';
import Swal from 'sweetalert2';

import PaymentCard from 'react-payment-card-component'
import ModalCard from '../modalCard';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    marginTop: 15,
    // flexDirection: 'column',
  },
  creditCardContainer:{
    display: 'flex',
    flexDirection: 'column',
  },
  creditCardContent:{
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  },
  btnCard:{
    backgroundColor: 'var(--primary-cor)',
    outline: 'none',
    border: 'none',
    padding: '12px',
    borderRadius: '4px',
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
  }
}));

const CreditCards = ({setLoading}) => {

  const classes = useStyles();

  const [open, setOpen] = useState(false)

  const [card, setCard] = useState({})
  const [isCard, setIsCard] = useState(false)

   useEffect(() => {

      function loadCard() {
       api.get('/cards/establishment')
       .then(res => {
         setCard(res.data)
         setIsCard(true)
       })
       .catch(err => {
          setIsCard(false)
         console.log('Error', err)
         //Swal.fire()
       })
     }
     loadCard()
   }, [])

  return (
    <>
     {
          !card
          ?
          <h2>Você não possui cartão cadastrado.</h2>
          :
          <> 
          <Paper elevation={0} className={classes.paper} >
            <div className={classes.creditCardContainer} >
              <div className={classes.creditCardContent} >
                <h2>Você posssui um cartão cadastrado.</h2>

                {
                  card && 
                  <PaymentCard
                  bank="default"
                  model="personnalite"
                  brand={card.flag ? card.flag.toLowerCase() : ''}
                  number={`000000000000${card.last4CardNumber}`}
                  holderName=""
                  expiration={`${card.expirationMonth}/${card.expirationYear}`}
                  flipped={false}
                />

                }

                <h3 style={{marginTop: '30px'}} >Deseja trocar de cartão?</h3>
                <button onClick={() => setOpen(true)} className={classes.btnCard} >Trocar cartão</button>
              </div>
            </div>
            <ModalCard open={open} setOpen={setOpen} />
          </Paper>
          </>
      }
    </>
  )
}

export default CreditCards