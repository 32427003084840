import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Paper,
  Modal,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Box,
  Container,
  Grid,
  Checkbox,
} from '@material-ui/core';

import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import ObsSection from '../../../components/obsSection';

import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import './styles.css';

import { getId, logout } from '../../../utils/auth';

import api from '../../../utils/services';
import MaterialTable from 'material-table';

import Alert from '../../../components/modal/Alert';

import { useHistory } from 'react-router-dom';
import loadingGif from '../../../assets/loading.gif';
import Swal from 'sweetalert2';

import IntlCurrencyInput from 'react-intl-currency-input';

import defaultImage from '../../../assets/default-profile.png';
import { AddPromotionModal } from './components/AddPromotionModal';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  flexActionsAdd: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '10px',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#f5f4f6',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    marginTop: 15,
    // flexDirection: 'column',
  },
  paperModal: {
    position: 'absolute',
    width: '90%',
    margin: '0 auto',
    maxHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    // borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'scroll',
  },
  h1: {
    fontSize: 'clamp(14px, 28px, 24px)',
    lineHeight: '2rem',
  },
  cardCatalog: {
    display: 'flex',
    justifyContent: 'center',
    color: '#FFF',
    width: 'calc(100% - 5px)',
    padding: theme.spacing(2, 2),
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 49;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Catalogo() {
  let history = useHistory();

  const initialvaluesModalProduct = {
    name: '',
    description: '',
    price: '',
    active: true,
    type: '',
    category: '',
  };

  const [action, setAction] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [currency, setCurrency] = useState(0);
  const handleChangeCurrency = (event, value, maskedValue) => {
    event.preventDefault();
    setCurrency(value);
  };

  const [modalStyle] = useState(getModalStyle);
  const [categorias, setCategorias] = useState([]);
  const [selectCategoria, setSelectCategoria] = useState([]);
  const [selected, setSelected] = useState(false);
  const [itemSelected, setItemSelected] = useState(-1);
  const [idCategorySelected, setIdCategorySelected] = useState('');

  const [pageSelected, setPageSelected] = useState(1);
  const [loading, setLoading] = useState(false);

  const limitePage = 10;
  const [totalPages, setTotalPages] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [openModalCategoria, setOpenModalCategoria] = useState(false);
  const [openModalUpdateCategoria, setOpenModalUpdateCategoria] =
    useState(false);
  const [openModalDelete, setModalDelete] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesUpload, setImagesUpload] = useState([]);

  const [optionCategoria, setOpitionCategoria] = useState({ name: '' });
  const [valuesProduct, setValuesProduct] = useState(initialvaluesModalProduct);
  const [dataProduct, setDataProduct] = useState([]);

  const [categoryToModal, setCategoryToModal] = useState('');
  const [announcements, setAnnouncements] = useState('');

  const [productsIds, setProductsIds] = useState([]);

  const [openModalPromotion, setOpenModalPromotion] = useState(false);

  const handleOpenModalPromotion = () => {
    setOpenModalPromotion(true);
  };
  const handleCloseModalPromotion = () => {
    setOpenModalPromotion(false);
  };

  const handleModalDelete = () => {
    setAction(true);
  };

  const [valuesCategoria, setValuesVategoria] = useState('');

  const handleChangeProduct = (event) => {
    const { name, value } = event.target; // Target ==
    setValuesProduct({ ...valuesProduct, [name]: value });
  };

  const handleChangeCategoria = (event) => {
    const { name, value } = event.target;
    setValuesVategoria(value);
  };

  const handleChangeOption = (event) => {
    setOpitionCategoria(event.target.value);
  };

  const handleSubmitProducts = () => {
    setLoading(true);
    let formData = new FormData();

    formData.append('name', valuesProduct.name);
    formData.append('description', valuesProduct.description);
    console.log('valores', valuesProduct);
    if (currency !== '') {
      formData.append('price', currency);
    }
    if (valuesProduct.quantity) {
      formData.append('quantity', valuesProduct.quantity);
    }
    formData.append('active', valuesProduct.active);

    categoryToModal !== ''
      ? formData.append('category', idCategorySelected)
      : formData.append('category', valuesProduct.category);
    announcements !== ''
      ? formData.append('type', announcements)
      : formData.append('type', valuesProduct.type);

    Object.entries(imagesUpload).map(([key, value]) => {
      return formData.append('images', value);
    });

    // Funcionando

    api
      .post('/announcements', formData)
      .then((res) => {
        setLoading(false);
        Swal.fire({
          title: 'Sucesso',
          text: 'Produto cadastrado com sucesso',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((_) => {
          setSelectedFiles([]);
          setImages([]);
          setImagesUpload([]);
          setDataProduct([...dataProduct, res.data]);
          handleCloseModal();
        });
      })
      .catch((error) => {
        setLoading(false);
        setSelectedFiles([]);
        setImages([]);
        setImagesUpload([]);
        if (error.response.data.type === 'establishment_not_permited') {
          Swal.fire({
            title: 'Nenhum plano encontrado',
            text: 'você precisa contratar um plano',
            icon: 'warning',
            confirmButtonText: 'Ver planos',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.pathname = 'planos';
            }
          });
        } else {
          Swal.fire({
            title: 'Erro',
            text: 'Algo deu errado, tente novamente!',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
        console.error('Erro', error.response.data);
      });
  };

  const deleteAnnouncement = (id) => {
    api
      .delete(`/announcements/${id}`)
      .then((_) => {
        Swal.fire({
          title: 'Excluido',
          text: 'Excluido com sucesso',
          icon: 'success',
          confirmButtonText: 'OK',
        });
        const newProducts = dataProduct.filter((item) => item.id !== id);
        setDataProduct(newProducts);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo deu errado, tente novamente',
        });
        console.log('Deu erro:' + error);
      });
  };

  const handleDeleteProduct = (id) => {
    Swal.fire({
      title: 'Certeza que deseja excluir esse anúncio?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      denyButtonText: `Não`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAnnouncement(id);
      } else if (result.isDenied) {
        Swal.fire('Ok! o anúncio não foi excluido', '', 'info');
        handleCloseModal();
      }
    });
  };

  // Request categorias

  // Criar categoria
  const handleSubmitCategoria = async (name, id) => {
    setLoading(true);
    let data = {
      name: name,
      establishment: getId(),
    };

    api
      .post('/category-products-services', data)
      .then((_) => {
        setLoading(false);
        Swal.fire({
          title: 'Sucesso',
          text: 'Nova categoria adicionada',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((_) => window.location.reload());
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: 'Erro',
          text: 'Sua sessão expirou, faça o Login novamente',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        console.log('Deu erro:' + error);
        //  logout();
        //  window.location.reload()
      });
  };

  // Atualizar categoria
  const handleUpdateCategoria = async (name, id) => {
    setLoading(true);
    const token = localStorage.getItem('token')?.slice(1, -1);
    const data = {
      name: name,
    };

    api
      .put(`/category-products-services/${id}`, data)
      .then((_) => {
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Atualizado',
          text: 'Sua categoria foi atualizada',
        });
        const index = categorias.findIndex((categoria) => categoria.id === id);
        const newCategorias = [...categorias];
        newCategorias[index].name = name;
        setCategorias(newCategorias);
        //window.location.reload()
      })
      .catch((error) => {
        setLoading(false);
        alert('Sua sessão expirou, faça o Login novamente');
        console.log('Deu erro:' + error);
        //  logout();
        //  window.location.reload()
      });
  };

  // Excluir categoria
  const handleDeleteCategoria = async (id) => {
    setLoading(true);
    //const token = localStorage.getItem('token')?.slice(1,-1)
    //console.log(id)

    api
      .delete(`/category-products-services/${id}`)
      .then((_) => {
        setLoading(false);
        Swal.fire('Seu anúncio foi excluido');
        handleCloseDeleteModal();
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo deu errado, tente novamente',
        });
        console.log('Deu erro:' + error.response);
        handleCloseDeleteModal();
        //  logout();
        //  window.location.reload()
      });
  };

  // Tratar com modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFiles([]);
    setImages([]);
    setImagesUpload([]);
  };

  const handleOpenModalCategoria = () => {
    setOpenModalCategoria(true);
  };

  const handleCloseModalCategoria = () => {
    setOpenModalCategoria(false);
  };

  const handleOpenModalUpdateCategoria = () => {
    setOpenModalUpdateCategoria(true);
  };

  const handleCloseModalUpdateCategoria = () => {
    setOpenModalUpdateCategoria(false);
  };

  const handleOpenDeleteModal = () => {
    setModalDelete(true);
  };

  const handleCloseDeleteModal = () => {
    setModalDelete(false);
  };

  //------------- Previw de images -----------
  const handleImageChange = (e) => {
    const MAX_LENGTH = 4;

    if (Array.from(e.target.files).length > MAX_LENGTH) {
      e.preventDefault();
      alert(`Não pode ter mais de  ${MAX_LENGTH} imagens`);
      return;
    }
    let arr = imagesUpload;

    const filesArrayUpload = Array.from(e.target.files).map((item) =>
      arr.push(item)
    );
    setImagesUpload(arr);

    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );

      // for(let i = 0; i < e.target.files.length; i++){
      /*  for(let i = 0; i < filesArrayUpload.length; i++){
         setImagesUpload(...imagesUpload, filesArrayUpload[i])
      } **/

      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map(
        (file) => {
          setImages(file);

          URL.revokeObjectURL(file);
        } // avoid memory leak
      );
    }
  };

  const deletePhotos = (index) => {
    let newArray = selectedFiles.filter((item, Nindex) => Nindex !== index);
    setSelectedFiles(newArray);
  };

  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <div className="img-previw-container" key={index}>
          <img src={photo} alt="" key={photo} />;
          {/* { console.log('---',images)} */}
          <button onClick={() => deletePhotos(index)}>X</button>
        </div>
      );
    });
  };
  // ---------- End Previw de images ---------

  const handleLoadProduct = (id) => {
    api
      .get(
        `/announcements/category/${id}?page=${pageSelected}&limit=${limitePage}`
      )
      .then((res) => {
        //console.log(Math.round(res.data.count/limitePage))
        setTotalPages(Math.ceil(res.data.count / limitePage));
        setDataProduct(res.data.announcements);
      })
      .catch((error) => {
        console.log('error', error.response);
        if (error.response.status === 401) {
          alert('Sua sessão expirou, faça o Login novamente');
          //logout();
          window.location.reload();
        }
      });
  };

  const handlePagination = (action) => {
    console.log(totalPages);
    if (action === 'add' && pageSelected + 1 <= totalPages) {
      api
        .get(
          `/announcements/category/${idCategorySelected}?page=${
            pageSelected + 1
          }&limit=${limitePage}`
        )
        .then((res) => {
          setDataProduct(res.data.announcements);
        })
        .catch((error) => {
          console.log('error', error.response);
          if (error.response.status === 401) {
            alert('Sua sessão expirou, faça o Login novamente');
            //logout();
            window.location.reload();
          }
        });
    } else if (action === 'prev' && pageSelected + 1 >= 1) {
      api
        .get(
          `/announcements/category/${idCategorySelected}?page=${
            pageSelected - 1
          }&limit=${limitePage}`
        )
        .then((res) => {
          setDataProduct(res.data.announcements);
        })
        .catch((error) => {
          console.log('error', error.response);
          if (error.response.status === 401) {
            alert('Sua sessão expirou, faça o Login novamente');
            //logout();
            window.location.reload();
          }
        });
    } else {
      alert('Não existe');
    }
  };

  useEffect(() => {
    async function loadProducts() {
      api
        .get(`/category-products-services/establishment/${getId()}`)
        .then((res) => {
          console.log(res.data);
          setCategorias(res.data);
          if (res.data.length > 0) {
            setSelected(true);
            setSelectCategoria(res.data[0]);
            setItemSelected(0);
            setIdCategorySelected(res.data[0].id);
            setPageSelected(1);
            handleLoadProduct(res.data[0].id);
            setCategoryToModal(res.data[0].name);
          }
        })
        .catch((error) => {
          console.log('error', error.response);
          if (error.response.status === 401) {
            alert('Sua sessão expirou, faça o Login novamente');
            logout();
            window.location.reload();
          }
        });
    }
    loadProducts();
  }, []);

  const classes = useStyles();

  const contentModalUpdateCategoria = (
    <div style={modalStyle} className={classes.paperModal}>
      <h2 id="simple-modal-title">Redefinir nome</h2>
      <p id="simple-modal-description">Redefina o nome da categoria</p>
      <div className="modal-products-container">
        <h4>Categoria do Produto</h4>
        <input
          onChange={handleChangeCategoria}
          name="nome"
          placeholder="Redefinir nome da categoria"
          type="text"
        />
      </div>

      <div style={{ textAlign: 'right', marginTop: 30 }}>
        <button
          onClick={() => setOpenModalUpdateCategoria(false)}
          className="btn-adc-item"
        >
          Cancelar
        </button>
        <button
          onClick={() => {
            handleUpdateCategoria(valuesCategoria, selectCategoria.id);
            handleCloseModalUpdateCategoria();
          }}
          style={{
            backgroundColor: '#1976d2',
            color: '#FFF',
            marginLeft: 10,
          }}
          className="btn-adc-item"
        >
          Salvar
        </button>
      </div>
    </div>
  );

  const contentModalCategoria = (
    <div style={modalStyle} className={classes.paperModal}>
      <h2 id="simple-modal-title">Adicione uma nova Categoria</h2>
      <p id="simple-modal-description">
        Escolha o nome para uma nova categoria. Após salve e adicione os itens.
      </p>

      <div className="modal-products-container">
        <h4>Categoria do Produto</h4>
        <input
          onChange={handleChangeCategoria}
          name="nome"
          placeholder="Digite o nome da nova categoria"
          type="text"
        />
      </div>

      <div style={{ textAlign: 'right', marginTop: 30 }}>
        <button onClick={handleCloseModalCategoria} className="btn-adc-item">
          Cancelar
        </button>
        <button
          onClick={() => {
            handleSubmitCategoria(valuesCategoria, selectCategoria.id);
            handleCloseModalCategoria();
          }}
          style={{
            backgroundColor: '#1976d2',
            color: '#FFF',
            marginLeft: 10,
          }}
          className="btn-adc-item"
        >
          Salvar
        </button>
      </div>
    </div>
  );

  const contentModalItem = (
    <div style={modalStyle} className={classes.paperModal}>
      <h2 id="simple-modal-title">Adicione um novo anúncio</h2>
      <p id="simple-modal-description">
        Selecione a categoria relacionada a seu produto
      </p>
      <br />
      {announcements === 'service' && (
        <>
          <p className="service-modal-planos">
            Obs: Para adicionar um serviço você precisa ter um plano contratado.{' '}
            <br></br>
            <span onClick={() => (window.location.pathname = 'planos')}>
              Ainda não tem? Confira nossos planos aqui!
            </span>
          </p>
        </>
      )}
      <div className="form-modal-products">
        <div>
          <div className="modal-products-container">
            <h4>Você vai cadastrar produto ou serviço?</h4>
            <select
              disabled
              onChange={handleChangeProduct}
              name="type"
              type="text"
            >
              <option>Escolha uma opção</option>
              <option
                selected={announcements === 'product' ? true : false}
                value={'product'}
              >
                Produto
              </option>
              <option
                selected={announcements === 'service' ? true : false}
                value={'service'}
              >
                Serviço
              </option>
            </select>
          </div>

          <div className="modal-products-container">
            <h4>Categoria</h4>
            <select onChange={handleChangeProduct} name="category" type="text">
              <option>Escolha uma categoria</option>
              {categorias.map((item) => (
                <option
                  selected={item.name === categoryToModal ? true : false}
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="modal-products-container">
            <h4>Nome</h4>
            <input
              onChange={handleChangeProduct}
              name="name"
              placeholder="Qual o nome do produto/serviço?"
              type="text"
            />
          </div>

          <div className="modal-products-container">
            <h4>Descrição</h4>
            <textarea
              onChange={handleChangeProduct}
              name="description"
              placeholder="Uma breve descrição sobre"
              type="text"
            />
          </div>

          <div className="modal-products-container">
            <h4>Preço {announcements === 'service' && '(opcional)'} </h4>
            <IntlCurrencyInput
              currency="BRL"
              config={currencyConfig}
              onChange={handleChangeCurrency}
            />
          </div>

          {announcements !== 'service' && (
            <div className="modal-products-container">
              <h4>Quantidade para venda</h4>
              <input
                onChange={handleChangeProduct}
                name="quantity"
                placeholder="Qual a quantidade para venda?"
                type="number"
                onWheel={(e) => e.target.blur()}
              />
            </div>
          )}

          <div>
            <div className="modal-products-container">
              <h4>Status</h4>
              <select onChange={handleChangeProduct} name="active" type="text">
                <option>Escolha uma opção</option>
                <option value={true}>Publicado</option>
                <option value={false}>Invisível</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <h4>Envie as imagens</h4>
          <span>A primeira imagem será a imagem de destaque</span>
          <div>
            <input
              maxLength={4}
              type="file"
              id="file"
              multiple
              onChange={handleImageChange}
            />
            <div className="label-holder">
              <label htmlFor="file" className="label">
                <i className="material-icons">add_a_photo</i>
              </label>
            </div>
            <div className="result">{renderPhotos(selectedFiles)}</div>
          </div>
        </div>
      </div>

      <div style={{ textAlign: 'right', marginTop: 30 }}>
        <button onClick={handleCloseModal} className="btn-adc-item">
          Cancelar
        </button>
        <button
          onClick={() => {
            handleSubmitProducts();
          }}
          style={{
            backgroundColor: '#1976d2',
            color: '#FFF',
            marginLeft: 10,
          }}
          className="btn-adc-item"
        >
          Salvar
        </button>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {loading ? (
        <div className="loading-container">
          <img src={loadingGif} alt="Gif de carregamento" />
        </div>
      ) : null}

      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <ObsSection
            icon={<LibraryBooksIcon />}
            title="Crie categorias de produtos"
            message="Para seu perfil ficar mais organizado e com e de fácil leitura, separe seus produtos por categoria."
          />

          <div style={{ textAlign: 'right', marginTop: '20px' }}>
            <button
              onClick={handleOpenModalCategoria}
              style={{ marginBottom: '1rem' }}
              className="btn-adc-item"
            >
              <span>+</span>Adicionar categoria
            </button>
            <Modal
              open={openModalCategoria}
              onClose={handleCloseModalCategoria}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {contentModalCategoria}
            </Modal>
          </div>

          <Grid container spacing={3}>
            <Grid
              id="style-3"
              style={{ height: categorias.length > 0 ? 'auto' : '0px' }}
              className="card-catalog-container scrollbar"
              item
              xs={12}
            >
              {categorias.map((item, indexCategoria) => (
                <Paper
                  elevation={0}
                  onClick={() => {
                    setSelectCategoria(categorias[indexCategoria]);
                    setSelected(true);
                    setItemSelected(indexCategoria);
                    setIdCategorySelected(item.id);
                    setPageSelected(1);
                    handleLoadProduct(item.id);
                    setCategoryToModal(item.name);
                  }}
                  className={classes.cardCatalog}
                  style={{
                    backgroundColor: `${
                      indexCategoria === itemSelected ? '#1976d2' : '#aaa'
                    }`,
                  }}
                  key={indexCategoria}
                >
                  <span>{item.name}</span>
                </Paper>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={0} style={{ marginTop: 30 }}>
                <div className="container-catalog">
                  <div>
                    {selected === true ? (
                      <div className="table-category-title">
                        <h2>Categoria: {selectCategoria.name}</h2>
                        <div>
                          <button
                            onClick={handleOpenModalUpdateCategoria}
                            style={{
                              marginRight: 10,
                              backgroundColor: 'var(--orange)',
                            }}
                          >
                            Atualizar
                          </button>
                          <button
                            onClick={handleOpenDeleteModal}
                            style={{ backgroundColor: 'var(--red)' }}
                          >
                            Deletar
                          </button>
                        </div>
                      </div>
                    ) : (
                      <span></span>
                    )}
                    <Dialog
                      open={openModalDelete}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={handleCloseDeleteModal}
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        {'Você deseja realmente excluir essa categoria?'}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          Após você excluir essa catégoria, todos os seus
                          produtos cadastrados nela serão perdidos.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleCloseDeleteModal}
                          color="primary"
                        >
                          Não
                        </Button>
                        <Button
                          onClick={() =>
                            handleDeleteCategoria(selectCategoria.id)
                          }
                          color="secondary"
                        >
                          Sim
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Modal
                      open={openModalUpdateCategoria}
                      onClose={handleCloseModalCategoria}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                    >
                      {contentModalUpdateCategoria}
                    </Modal>
                  </div>
                  <div>
                    {/* {selected === false && dataProduct.length > 0 */}

                    {categorias.length > 0 ? (
                      <div className={classes.flexActionsAdd}>
                        {/* <h1  style={{ flex: "auto"}} className={classes.h1} >Escolha uma categoria ou adicione uma nova</h1> */}

                        {/* <button
                          disabled={productsIds.length === 0 ? true : false}
                          onClick={handleOpenModalPromotion}
                          className="btn-adc-item"
                        >
                          <span>+</span>Adicionar Desconto
                        </button> */}

                        <button
                          onClick={() => {
                            handleOpenModal();
                            setAnnouncements('service');
                          }}
                          className="btn-adc-item"
                        >
                          <span>+</span>Adicionar Serviço
                        </button>

                        <button
                          onClick={() => {
                            handleOpenModal();
                            setAnnouncements('product');
                          }}
                          className="btn-adc-item"
                        >
                          <span>+</span>Adicionar Produto
                        </button>
                      </div>
                    ) : (
                      <div className={classes.flexActionsAdd}>
                        <h3>
                          Você ainda não tem uma categorias. Crie uma para após
                          cadastrar um anúncio
                        </h3>
                      </div>
                    )}
                    {selected === false ? null : (
                      <>
                        {/* <h1>{selectCategoria.name}</h1> */}
                        <MaterialTable
                          columns={[
                            {
                              title: 'Imagem',
                              field: 'image',
                              render: (rowData) =>
                                rowData.images.length > 0 ? (
                                  <img
                                    src={rowData.images[0].url}
                                    style={{
                                      width: 70,
                                      height: 70,
                                      borderRadius: '50%',
                                      objectFit: 'contain',
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={defaultImage}
                                    style={{
                                      width: 70,
                                      height: 70,
                                      borderRadius: '50%',
                                      objectFit: 'contain',
                                    }}
                                    alt="Não existe imagem"
                                  />
                                ),
                            },
                            { title: 'Nome', field: 'name' },
                            { title: 'Preço', field: 'price' },
                            {
                              title: 'Status',
                              field: 'active',
                              render: (rowData) =>
                                rowData.active === true ? 'Ativo' : 'Invisível',
                            },
                            { title: 'Quantidade', field: 'quantity' },
                            {
                              title: 'Tipo',
                              field: 'type',
                              render: (rowData) =>
                                rowData.type === 'product'
                                  ? 'Produto'
                                  : 'Serviço',
                            },
                          ]}
                          data={dataProduct}
                          title="Produtos"
                          localization={{
                            toolbar: {
                              searchPlaceholder: 'Pesquisar',
                            },
                            header: {
                              actions: 'Ações',
                            },
                            body: {
                              emptyDataSourceMessage:
                                'Não há registros a serem exibidos',
                            },
                          }}
                          actions={[
                            {
                              icon: 'visibility',
                              position: 'row',
                              tooltip: 'Visualizar produto',
                              onClick: (event, rowData) =>
                                history.push(`actions-produtos/${rowData.id}`),
                            },
                            {
                              icon: 'delete',
                              position: 'row',
                              tooltip: 'Deletar produto',
                              onClick: (event, rowData) => {
                                //  handleModalDelete()
                                handleDeleteProduct(rowData.id);
                              },
                            },
                          ]}
                          options={{
                            actionsColumnIndex: -1,
                            paging: false,
                            showSelectAllCheckbox: true,
                            selection: true,
                          }}
                          onSelectionChange={(rows) => setProductsIds(rows)}
                        />
                        <div className="pagination-container">
                          <div className="pagination-content">
                            <button
                              disabled={pageSelected - 1 >= 1 ? false : true}
                              onClick={() => {
                                setPageSelected(pageSelected - 1);
                                handlePagination('prev');
                              }}
                            >
                              Anterior
                            </button>
                            <input type="text" value={pageSelected} />
                            <button
                              disabled={
                                pageSelected + 1 <= totalPages ? false : true
                              }
                              onClick={() => {
                                setPageSelected(pageSelected + 1);
                                handlePagination('add');
                              }}
                            >
                              Próximo
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <Modal
                    open={openModalPromotion}
                    onClose={handleCloseModalPromotion}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <AddPromotionModal
                      products={productsIds}
                      handleClose={handleCloseModalPromotion}
                    />
                  </Modal>

                  {action === true ? (
                    <Alert
                      Title="Tem certeza que deseja excluir esse anúncio?"
                      SubTitle="Clicando em 'Sim', todas as informações desse anúncio será perdida"
                      onClose={() => setAction(false)}
                      onSubmit={() => setSubmit(true)}
                    />
                  ) : null}

                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    {contentModalItem}
                  </Modal>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}
