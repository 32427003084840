import React, {useState, useEffect} from 'react'
import toast, { Toaster } from 'react-hot-toast';
import {requestForToken,onMessageListener} from '../../../utils/firebase';

function Notificacao() {

  const [isTokenFound, setTokenFound] = useState(true);  

  const [notification, setNotification] = useState({title: '', body: ''});
  
  const notify = () =>  toast(<ToastDisplay/>);
  function ToastDisplay() {
    return (
      <div>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  onMessageListener()
  .then((payload) => {
    console.log("payload", payload);
    setNotification({title: payload?.notification?.title, body:  payload?.notification?.body });     
  })
  .catch((err) => console.log('failed: ', err));

  useEffect(() => {
    if (notification?.title ){
     notify()
    }
  }, [notification])
  useEffect(()=>{
    function exec(){
      requestForToken(setTokenFound);
    }
    exec();
  },[])
  
  return (
    <div className="App">
          {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
          position: 'absolute',
          top: 20,
          right: 20,
          minWidth: 200
        }}>
         <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">{notification.title}</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>  */}
      <div>

      <Toaster/>
      {isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>

      }
        {!isTokenFound && 
        <h1> Need notification permission ❗️</h1> 
      }
      </div>
      
      
    </div>
  );
}

export default Notificacao;
