import React, { useEffect, useState } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import { Paper, Box, Container, Grid } from '@material-ui/core';
import CardInfo from '../../../components/card-info';

import AssignmentIcon from '@material-ui/icons/Assignment';
import BarChartIcon from '@material-ui/icons/BarChart';
import Storefront from '@material-ui/icons/Storefront';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MoodBad from '@material-ui/icons/MoodBad';
import CloseIcon from '@material-ui/icons/Close';

// Utilitarios
import { logout, myToken } from '../../../utils/auth';

// Dependência

import './styles.css';
import api from '../../../utils/services';
import CardInsight from '../../../components/card-insights';
import CashPlusSvg from '../../../assets/svg/CashPlusSvg';
import CashSyncSvg from '../../../assets/svg/CashSyncSvg';
import CashFastSvg from '../../../assets/svg/CashFasSvg';
import TopSellingProducts from '../../../components/top-selling-products';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#f5f4f6',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    // flexDirection: 'column',
  },
  status: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'calc(em + 1vw)',
  },
  larger: {
    maxWidth: '13em',
    maxHeight: '13em',
    width: '12em',
    height: '12em',
  },
  name: {
    fontSize: 'calc(1em + 2vw)',
    lineHeight: '2rem',
  },
  description: {
    fontSize: 'calc(1em + 1vw)',
    lineHeight: '2rem',
    marginTop: '1rem',
  },
  alertNotification: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'var(--white)',
    gap: 6,
    padding: 4,
  },
}));

export default function Dashboard() {
  // const user = useContext(UserContext)
  const [establishment, seteEtablishment] = useState({});
  const [isImg, setIsImg] = useState(false);

  const classes = useStyles();

  const [isNotification, setIsNotification] = useState(true);
  const [isBankInfo, setIsBankInfo] = useState(true);
  const [isAddress, setIsAddress] = useState(true);

  const [insights, setInsights] = useState({});

  const handleAlertSignUp = (type) => {
    switch (type) {
      case 'notification':
        setIsNotification(true);
        break;
      case 'bank':
        setIsBankInfo(true);
        break;
      case 'address':
        setIsAddress(true);
        break;
      default:
        break;
    }
  };

  const formatCurrency = (number) => {
    const roundedNumber = parseFloat(number).toFixed(2);
    const formattedNumber = roundedNumber.toString().replace('.', ',');
    return `R$ ${formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
  };

  useEffect(() => {
    async function loadEstablishment() {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') setIsNotification(false);

      if (Object.keys(establishment).length === 0) {
        api
          .get('/establishments/self')
          .then((res) => {
            if (res.data.user.bankAccount === null) {
              setIsBankInfo(false);
            }
            if (res.data.hasAddress === false) {
              console.log('entrouif');
              setIsAddress(false);
            }
            seteEtablishment(res.data);
            setIsImg(true);
          })
          .catch((error) => {
            alert('Sua sessão expirou, faça o Login novamente');
            logout();
            window.location.reload();
          });
      }

      api
        .get('/indicators/balances')
        .then((res) => {
          setInsights(res.data);
        })
        .catch((err) =>
          console.log('Não foi possivel trazer os insights', err)
        );
    }
    loadEstablishment();
  }, []);

  return (
    <div className={classes.root}>
      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={0} className="container-card-home">
                {isImg === false ? null : (
                  <div className="container-avatar-home">
                    <img
                      className="avatar-home"
                      alt="Foto do empreendedor"
                      src={establishment.user.profileImage}
                    />
                  </div>
                )}
                <div className={classes.infoContainer}>
                  <h1 className={classes.name}>{establishment.name}</h1>
                  <p className={classes.description}>
                    {establishment.description}
                  </p>
                </div>
              </Paper>

              {/* Notificações */}
              <Paper
                elevation={0}
                style={{
                  display: isNotification === true ? 'none' : 'flex',
                  backgroundColor: 'var(--orange)',
                }}
                className={classes.alertNotification}
              >
                <MoodBad />
                <p style={{ flexGrow: '2', fontSize: '24px' }}>
                  Suas notificações estão desativadas. Ative para receber
                  notificações sobre suas vendas
                </p>
                <CloseIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleAlertSignUp('notification')}
                />
              </Paper>

              {/* Informações bancarias */}
              <Paper
                elevation={0}
                style={{
                  display: isBankInfo === true ? 'none' : 'flex',
                  cursor: 'pointer',
                  backgroundColor: 'var(--red)',
                }}
                className={classes.alertNotification}
                onClick={() =>
                  (window.location.pathname = '/cadastro-bancario')
                }
              >
                <MoodBad />
                <p style={{ flexGrow: '2', fontSize: '24px' }}>
                  Você não cadastrou suas informações bancárias.{' '}
                  <b>Clique aqui para ativar</b>
                </p>
                <CloseIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleAlertSignUp('bank')}
                />
              </Paper>

              {/* Informações Endereço */}
              <Paper
                elevation={0}
                style={{
                  display: isAddress === true ? 'none' : 'flex',
                  cursor: 'pointer',
                  backgroundColor: 'var(--red)',
                }}
                className={classes.alertNotification}
                onClick={() =>
                  (window.location.pathname = '/cadastro-endereco')
                }
              >
                <MoodBad />
                <p style={{ flexGrow: '2', fontSize: '24px' }}>
                  Você não cadastrou seu endereço.{' '}
                  <b>Clique aqui para cadastrar</b>
                </p>
                <CloseIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleAlertSignUp('address')}
                />
              </Paper>
            </Grid>

            {Object.entries(insights).length > 0 && (
              <>
                <Container maxWidth="lg" style={{ marginTop: '60px' }}>
                  <div
                    style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}
                  >
                    <CardInsight
                      icon={<CashPlusSvg style={{ fill: '#FFF' }} />}
                      title="Valor disponível"
                      indicator={formatCurrency(insights.availableAmount)}
                    />
                    <CardInsight
                      icon={<CashSyncSvg style={{ fill: '#FFF' }} />}
                      title="Aguardando para saque"
                      indicator={formatCurrency(insights.waitingFundsAmount)}
                    />
                    <CardInsight
                      icon={<CashFastSvg style={{ fill: '#FFF' }} />}
                      title="Valor transferido"
                      indicator={formatCurrency(insights.transferredAmount)}
                    />
                  </div>
                </Container>
                <Container
                  maxWidth="lg"
                  style={{
                    width: '100%',
                    gap: '20px',
                    marginTop: '20px',
                    // display: 'grid', gridTemplateColumns: '1fr 1fr'
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      flexWrap: 'wrap',
                      flex: 'auto',
                    }}
                  >
                    <CardInsight
                      icon={<CashPlusSvg style={{ fill: '#FFF' }} />}
                      title="Vendas totais"
                      indicator={insights.totalSales}
                    />
                    <CardInsight
                      icon={<CashSyncSvg style={{ fill: '#FFF' }} />}
                      title="Totais de vendas por dia"
                      indicator={insights.totalSalesPerDay}
                    />
                  </div>

                  <TopSellingProducts
                    bestSellingProductsList={insights.bestSellingProductsList}
                  />
                </Container>
              </>
            )}
            <Container maxWidth="lg" style={{ marginTop: '60px' }}>
              <Grid spacing={2} container style={{ gap: '10px' }}>
                <CardInfo
                  grid="3"
                  title="Meus pedidos"
                  description="Veja se alguém fez um novo pedido no seu empreendimento"
                  icon={<AssignmentIcon style={{ color: '#FFF' }} />}
                  bgIcon="#EA5B92"
                  navigation="pedidos"
                />
                <CardInfo
                  grid="3"
                  title="Editar perfil"
                  description="Edite as principais informações do seu empreendimento"
                  icon={<Storefront style={{ color: '#FFF' }} />}
                  bgIcon="#16C2A4"
                  navigation="perfil"
                />
                <CardInfo
                  grid="3"
                  title="Cadastrar Produto"
                  description="Crie um catalogo e adicione seus produtos de uma forma muito fácil"
                  icon={<LibraryBooksIcon style={{ color: '#FFF' }} />}
                  bgIcon="#FD8237"
                  navigation="catalogo"
                />
                <CardInfo
                  grid="3"
                  title="Story"
                  description="Que tal postar um story? clique aqui e poste!"
                  icon={<BarChartIcon style={{ color: '#FFF' }} />}
                  bgIcon="#16C2A4"
                  navigation="story"
                />
                <CardInfo
                  grid="3"
                  title="Devoluções"
                  description="Alguma da suas compras possa ter dado errado, acesse e descubra"
                  icon={<MoodBad style={{ color: '#FFF' }} />}
                  bgIcon="#EA5B92"
                  navigation="devolucao&cancelados"
                />
              </Grid>
            </Container>
          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}
